import './titlebar.css';
import { useState, useEffect } from 'react';


function RyannnIcon() {
    const [isHovered, setIsHovered] = useState(false);
    const [catForPage, setCatForPage] = useState('');


    const hoverStyle = {
        borderRadius: isHovered ? '25px' : '20px',
        transition: 'border-radius 0.3s ease'
    }



    useEffect(() => {
        const catIconPaths = [
            '/RandomCatIcons/Kitten1_512.png',
            '/RandomCatIcons/Kitten2_500.png',
            '/RandomCatIcons/sittykitty.jpg'
        ];
        setCatForPage(catIconPaths[Math.floor(Math.random() * catIconPaths.length)]);
    }, []);

    return (
        <div className="ryannn-icon">
            <img src={process.env.PUBLIC_URL + catForPage} alt={"Goofy lookin kitty"}
                 style={hoverStyle}
                 onMouseEnter={() => setIsHovered(true)}
                 onMouseLeave={() => setIsHovered(false)}
                 onClick={() => window.location.href = "https://ryannn.me"}/>
        </div>
    );
}

function TitleBar() {

    return (
        <div className="title-bar">
            <RyannnIcon />
            <h3 style={{color: 'white'}}>Ryannn</h3>
            <h3 style={{color: 'red'}} id={"menu"}> </h3>
        </div>
    );
}

export { TitleBar };