// import logo from './logo.svg';
import './App.css';
import { TitleBar } from './titleBar/titlebar';
import { useState, useEffect } from 'react';
import { ReactComponent as VscodeIcon} from "./SkillsIcons/vscode.svg";
import { ReactComponent as GithubIcon} from "./SkillsIcons/github.svg";
import { ReactComponent as PythonIcon} from "./SkillsIcons/python.svg";
import { ReactComponent as JSIcon } from "./SkillsIcons/js.svg";
import { ReactComponent as ReactIcon } from "./SkillsIcons/react.svg";
import { ReactComponent as LinuxIcon } from "./SkillsIcons/Tux.svg";




function ProfilePicture() {
    const [rotate, setRotate] = useState(false);
    const [rotated, setRotated] = useState(false);
    const [isSpinning, setIsSpinning] = useState(false);
    const [catCount, setCatCount] = useState(0);
    const [timesCatSpun, setTimesCatSpun] = useState(0);


    // curl 'http://70.163.237.16:8000/get_cat_count'
    // curl 'http://70.163.237.16:8000/increment_cat_count'

    const handleImageClick = () => {
        if (!isSpinning) {
            if (timesCatSpun === 10) {
                setCatCount(1);
            } else if (!(timesCatSpun > 10)) {

                setRotate(true);
                setRotated(true);
                setIsSpinning(true);
                setCatCount(catCount + 1);
                setTimesCatSpun(timesCatSpun + 1);

                fetch('https://ryannn.live/increment_cat_count')
                    .then(response => response.text())
                    .then(data => console.log('Cat count incremented:', data))
                    .catch(error => console.error('Error incrementing cat count:', error));
            }
        }
    };

    const handleAnimationEnd = () => {
        setIsSpinning(false);
        setRotate(false);
    };

    useEffect(() => {
        // Reset the rotated state when the page is reloaded
        setRotated(false);

        fetch('https://ryannn.live/get_cat_count')
            .then(response => response.text())
            .then(data => setCatCount(parseInt(data)))
            .catch(error => console.error('Error fetching cat count:', error));
    }, []);

    return (
        <div>
            {10 > timesCatSpun ?
                <img src={process.env.PUBLIC_URL + "/kitty.jpg"}
                     alt={"Cowboy hat cat"}
                     className={rotate ? 'rotate' : ''}
                     onClick={handleImageClick}
                     onAnimationEnd={handleAnimationEnd}
                />:
                <img src={process.env.PUBLIC_URL + "/explode.gif"} alt="Explosion GIF"/>
            }

            {rotated && 10 > timesCatSpun ? <p>Cat has been spun <strong>{catCount}</strong> times!</p> : <p>😺</p>}
            {9 < timesCatSpun ? <p>Cat go kaboom :(</p> : null}
        </div>
    );
}

function Skills() {
    const vscodeDesc = "vs code :P";
        // "<strong><a href='https://code.visualstudio.com/'>Visual Studio Code</a></strong> is a text editor geared towards software development that I have" +
        // " found myself using for a large majority of my projects. In addition to using it in School, I have had" +
        // " <strong><i>years</i></strong> of experience with it, building my own projects <u>(That you can check out on my <a href='https://github.com/ryannn2424'>GitHub!</a>)</u> from" +
        // " the beginning in the integrated terminal to the end with professional extensions. It was through <strong>VS Code</strong>" +
        // " that I learned most of my skills in programs like Git, and also where I learned to code in languages like" +
        // " Python and Javascript. Without a doubt, I can say that I have effectively mastered Visual Studio Code.";
    const githubDesc = "github :o";
        // "Github in the simplest description is a website dedicated to Version Control, yet as" +
        // " time has gone on, I've realized it's a lot more. In fact, Github is the reason this website is even up" +
        // " right now (This page is hosted on Github Pages!!). I have been using Github for over half a decade at this" +
        // " point, and I have recently learned how to truly utilize it to its full potential. By extension through Git" +
        // " itself, I have mastered how Commits, Branching, Pushing and Pulling, and a lot more works. With confidence," +
        // " I can claim that I'm skillful in using Github to its full potential.";
    const pythonDesc = "Pyton :D";
        // "Python is a programming language that I just can't seem to get away from. Not only are" +
        // "most of my 'major' projects written in Python, but I used it to get a 5 on my AP Computer Science Principles" +
        // " test and am also career certified through Veriport.";
    const jsDesc = "javascript :)";
    const reactDesc = "REACT :OOO";
    const linuxDesc = "Linux D:";

    const [SkillTitle, setSkillTitle] = useState("Visual Studio Code");
    const [SkillDescription, setSkillDescription] = useState(vscodeDesc);
    const [textBackgroundColor, setTextBackgroundColor] = useState({ backgroundColor: '#282863' });
    const [textColor, setTextColor] = useState({ color: 'white' });

    function changeInfo(skillTi, skillDe) {
        setSkillTitle(skillTi);
        setSkillDescription(skillDe);

        switch (skillTi) {
            case "Visual Studio Code":
                setTextBackgroundColor({backgroundColor: '#007ACC'});
                setTextColor({color: 'white'});
                break;
            case "Github":
                setTextBackgroundColor({backgroundColor: '#24292E'});
                setTextColor({color: 'white'});
                break;
            case "Python":
                setTextBackgroundColor({backgroundColor: '#3776AB'});
                setTextColor({color: 'white'});
                break;
            case "Javascript":
                setTextBackgroundColor({backgroundColor: '#F0DB4F'});
                setTextColor({color: 'black'});
                break;
            case "React":
                setTextBackgroundColor({backgroundColor: '#61DAFB'});
                setTextColor({color: 'black'});
                break;
            case "Linux":
                setTextBackgroundColor({backgroundColor: '#bfbdbd'});
                setTextColor({color: 'black'});
                break;
        }
    }

    return (
        <>
            <div>
                <VscodeIcon className={"SkillButton"} onClick={() => changeInfo("Visual Studio Code", vscodeDesc)}/>
                <GithubIcon className={"SkillButton"} onClick={() => changeInfo("Github", githubDesc)}/>
                <PythonIcon className={"SkillButton"} onClick={() => changeInfo("Python", pythonDesc)}/>
                <JSIcon className={"SkillButton"} onClick={() => changeInfo("Javascript", jsDesc)}/>
                <ReactIcon className={"SkillButton"} onClick={() => changeInfo("React", reactDesc)}/>
                <LinuxIcon className={"SkillButton"} onClick={() => changeInfo("Linux", linuxDesc)}/>
            </div>
            <div className={"SkillText"} id="SkillTextContainer" style={textBackgroundColor}>
                <h2 style={textColor}>{SkillTitle}</h2>
                <p dangerouslySetInnerHTML={{ __html: SkillDescription }} style={textColor}></p>
            </div>
        </>
    );
}

function App() {
    return (
        <div className="App">
            <header className="App-header">
                {/*<img src={logo} className="App-logo" alt="logo" />*/}
                <TitleBar/>
                <h1>
                    🚧
                </h1>
                <p>Website under construction!</p>

                <div className="Introduction">
                    <ProfilePicture/>
                    <div className={"Introduction-text"}>
                        <h1>Ryan Ray</h1>
                        <p>
                            <li>I am <strong>17</strong> years old</li>
                            <li>I am currently enrolled as a High School Senior</li>
                        <li>I've been coding since I was about <strong>7</strong> years old</li>
                    </p>
                </div>
            </div>
            <div className={"Skills"}>
                <h1 style={{}}>Skills</h1>
                <h6 style={{color: "#9c9c9c"}}>Click on an icon!</h6>
                <Skills/>
            </div>

        </header>
    </div>
  );
}

export default App;
